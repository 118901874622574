.wrapper {
    display: flex;
    position: fixed;
    transition: margin .5s;
    background-color: white;
    flex-direction: column;
    width: 30%;
    height: 100vh;
    z-index: 1;
}

@media (max-width: 600px) {
    .wrapper {
      width: 100%;
      height: max-content;
      padding-bottom: 15px;
    }

    div.wrapper-1{
        margin-left: -100%;
    }

    button.nav__icon {
        top: -15%;
        left: 85%;
        background-color: lightskyblue;
        border-radius: 5px;
        font-size: 15px;
    }

    p.card-text {
        font-size: 10px;
    }
  }

.wrapper-1 {
    margin-left: -60%;
}

.wrapper.is-nav-open{
    position: absolute;
    margin-left: 0px;
    margin-top: 0px;
}

.nav {
    position: relative;
}

.nav__icon {
    position: absolute;
    top: 100%;
    left: 36%;
    font-size: 20px;
    cursor: pointer;
    transition: color .1s;
    background-color: transparent;
    color: white;
    outline: none;
    border: none;
    z-index: 1;
}

.card-text {
    font-size: 15px;
}

.card-title {
    color: black;
    font-size: 25px;
    font-family: 'Muli', sans-serif;
    font-weight: 800;
  }
  
