.website-body {
  display: flex;
  background-color: white;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.Header {
  padding-left: 0px;
  padding-left: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: max-content;
}

.Links {
  padding-left: 0px;
  padding-top: 20px;
}

.Title {
  color: black;
  font-size: 70px;
  font-family: 'Muli', sans-serif;
  font-weight: 600;
}

.container {
  padding-top: 10px;
  margin-right: 0px;
  margin-left: 10%;
  margin-bottom: 10px;;
}

.publications {
  height: 100vh;
  align-items: center;
}




@media only screen and (max-width: 600px) {
  .Title {
    font-size: 50px;
  }

  .TitleTwo {
    font-size: 30px;
  }

  .BodyText {
    font-size: 12px;
  }

  .publications {
    flex-direction: column;
    flex-wrap: inherit;
  }

  .container {
    margin-left: 10px;
    margin-top: 20px;
    max-width: 100vw;
  }
  
  .title-column {
    padding-top: 40px;
    padding-bottom: 20px;
    flex-grow: 0;

  }
  
}